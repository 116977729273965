import React, { useContext } from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Span, Text } from "../../components/Core"
import GlobalContext from "../../context/GlobalContext"

const StyledBox = styled(Box)`
  width: 100%;
  text-align: center;
`
const HighlightedText = styled(Span)`
  font-weight: 600;
  color: ${({ theme, gContext }) =>
    gContext.isInteractuar ? theme.colors.navyBlue : theme.colors.green};
`

const TopTitle = () => {
  const gContext = useContext(GlobalContext)

  return (
    <Container
      css={`
        padding-top: 12.5rem;
        ${gContext.isInteractuar &&
        `@media (max-width: 576px) {
          background: linear-gradient(180deg,#e7f9ff 0%,rgba(231, 248, 255, 0.05) 100%);
          padding-top: 9rem;
        }
        padding-top: 15rem;
          `}
      `}
    >
      <Row>
        <Col>
          <StyledBox>
            <Text
              mb={[2, 1, 2, 3]}
              fontSize={["1.5rem", "1.8rem", "2.0rem", "2.2rem", "2.5rem"]}
              color="primary"
              className={"text-center"}
              fontWeight={"700"}
              lineHeight={["35px", "35px", "36px", "42px", "50px"]}
            >
              Todo lo que necesitas <br /> en un solo lugar
              <HighlightedText gContext={gContext}>. </HighlightedText>
            </Text>
          </StyledBox>
        </Col>
      </Row>
    </Container>
  )
}

export default TopTitle
