import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useContext, useMemo } from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Text } from "../../components/Core"
import GlobalContext from "../../context/GlobalContext"
import { breakpoints } from "../../utils"

const query = graphql`
  query {
    InvoiceImage: file(
      relativePath: { eq: "howitworks/item_images_invoice.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }
    ReminderImage: file(
      relativePath: { eq: "howitworks/item_images_notification.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }
    ClientImage: file(
      relativePath: { eq: "howitworks/item_images_incomes.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }
    ReportImage: file(
      relativePath: { eq: "howitworks/item_images_finances.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }
  }
`

const RightDecorator = styled.div`
  position: absolute;
  height: 24.563rem;
  width: 12.25rem;
  border: 1px solid;
  border-color: ${props => props.theme.colors.footerDark};
  border-radius: 25rem 0 0 25rem;
  right: 0;
  transform: translate(0.1rem, 40rem);
  display: none;
  @media (min-width: ${breakpoints.xl}px) {
    display: block;
  }
`
const sectionAttibutes = data => [
  {
    icon: data.InvoiceImage,
    iconLegend: {
      title: "Title",
      subtitle: "Subtitle",
    },
    title: "Crea tus cuentas de cobro.",
    subtitle:
      "Puedes hacer tus cuentas de cobro o facturas de una manera muy fácil y programar sus envíos.",
  },
  {
    icon: data.ReminderImage,
    iconLegend: {
      title: "Title",
      subtitle: "Subtitle",
    },
    title: "Envía recordatorios de cobranza.",
    subtitle:
      "Cada vez que envíes una cuenta de cobro o factura podrás programar recordatorios para ti y tu cliente.",
  },
  {
    icon: data.ClientImage,
    iconLegend: {
      title: "Title",
      subtitle: "Subtitle",
    },
    title: "Aportes a seguridad social.",
    subtitle:
      "De forma fácil y rápida paga tu planilla de seguridad social en un solo lugar.",
  },
  {
    icon: data.ReportImage,
    iconLegend: {
      title: "Title",
      subtitle: "Subtitle",
    },
    title: "Anticipa tu dinero y reporte financiero.",
    subtitle:
      "Adelanta el pago de tus servicios y logra una visión global de tus finanzas.",
  },
]
const TextItem = ({ item }) => (
  <Col md={5} lg={5} className={`align-self-center `}>
    <Box
      pt="2"
      pb="2"
      css={`
        display: flex;
        flex-direction: column;
        justify-content: center;
      `}
    >
      <Text
        fontSize={["1.5rem", "1.5rem", "1.6rem", "1.7rem", "1.8rem"]}
        mb={2}
        css={`
          line-height: 30px;
          font-weight: 700;
          letter-spacing: 0.02em;
          color: ${({ theme }) => theme.colors.primary};
        `}
      >
        {item.title}
      </Text>
      <Text
        className="pt-3"
        fontSize="1rem"
        css={`
          line-height: 20px;
          font-weight: 400;
          letter-spacing: 0.02em;
          color: ${({ theme }) => theme.colors.primary};
        `}
      >
        {item.subtitle}
      </Text>
    </Box>
  </Col>
)

const ImageItem = ({ item }) => (
  <Col
    md={7}
    lg={7}
    css={`
      border-radius: 10px;
    `}
  >
    <GatsbyImage
      image={getImage(item.icon)}
      alt="left-image-decorator"
      loading={"lazy"}
      objectFit="contain"
    />
  </Col>
)

export const ImageTimeLine = () => {
  const gContext = useContext(GlobalContext)
  const { isMobile } = gContext

  return (
    <>
      <RightDecorator />
      <StaticQuery
        query={query}
        render={data => {
          const getSectionItems = useMemo(() => {
            return sectionAttibutes(data).map((item, index) => {
              return (
                <React.Fragment>
                  {index % 2 === 0 || isMobile ? (
                    <Row
                      css={`
                        margin-bottom: 5rem;
                      `}
                      className="align-items-left justify-content-between"
                    >
                      <TextItem item={item} />
                      <ImageItem item={item} />
                    </Row>
                  ) : (
                    <Row
                      css={`
                        margin-bottom: 5rem;
                      `}
                      className="align-items-left justify-content-between"
                    >
                      <ImageItem item={item} />
                      <TextItem item={item} />
                    </Row>
                  )}
                </React.Fragment>
              )
            })
          }, [])
          return (
            <Row
              className="align-items-left justify-content-center"
              css={`
                margin-top: 3.5rem;
                position: relative;
                &:before {
                  content: "";
                  width: 250px !important;
                  height: 250px !important;
                  left: -3rem;
                  bottom: 20%;
                  border: 0;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  filter: blur(50px);
                  -webkit-filter: blur(50px) !important;
                  -moz-filter: blur(50px) !important;
                  z-index: -1;
                  background-color: #dbf2fc;
                  background-size: 10%;
                }
              `}
            >
              {getSectionItems}
            </Row>
          )
        }}
      />
    </>
  )
}
