import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"
import { Box } from "../components/Core"
import PageWrapper from "../components/PageWrapper"
import Seo from "../components/Seo"
import { ImageTimeLine } from "../sections/howitworks/ImageTimeLine"
import { ItemsDescription } from "../sections/howitworks/ItemsDescription"
import TopTitle from "../sections/howitworks/TopTitle"
import PricingSection from "../sections/pricing/PricingSection"
import { breakpoints, device } from "../utils"

const query = graphql`
  query {
    TopImage: file(relativePath: { eq: "how-it-works-top-image.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 70
          blurredOptions: { width: 90 }
        )
      }
    }
    LeftImageDecorator: file(relativePath: { eq: "left-image-decorator.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 50 }
        )
      }
    }
  }
`

const ShapeTop = styled(Box)`
  top: 0;
  text-align: center;
  img {
    min-width: 80%;
  }
`

const BannerImageDecorator = styled.div`
  display: block;
  position: absolute;
  border: 1px solid;
  border-color: ${props => props.theme.colors.footerDark};
  border-radius: 0 25rem 25rem 0;
  top: 12.5rem;
  height: 24.563rem;
  width: 12.25rem;
`

const StyledPageWrapper = styled.div`
  background: linear-gradient(
    180deg,
    #e7f8ff 0%,
    rgba(231, 248, 255, 0.05) 20%
  );

  @media ${device.md} {
    background: none;
  }
`

const LeftTimelineDecorator = styled.div`
  position: absolute;
  height: 13.75rem;
  width: 8.75rem;
  border: 1px solid;
  border-color: ${props => props.theme.colors.green};
  border-radius: 18.75rem;
  display: none;
  left: 0;
  @media (min-width: ${breakpoints.md}px) {
    display: block;
  }
  @media (min-width: 1600px) {
    left: 5rem;
  }
  @media (min-width: 1800px) {
    left: 10rem;
  }
`

const HowItWorksPage = () => {
  return (
    <>
      <StyledPageWrapper>
        <PageWrapper footerDark headerBlue>
          <Seo title="¿Cómo funciona?" />
          <StaticQuery
            query={query}
            render={data => (
              <>
                <BannerImageDecorator />
                <TopTitle />
                <Container>
                  <ShapeTop>
                    <GatsbyImage
                      image={getImage(data.TopImage)}
                      alt={"left-form-image"}
                      loading="eager"
                    />
                  </ShapeTop>

                  <ItemsDescription />
                  <LeftTimelineDecorator />
                  <Box pt="10rem">
                    <ImageTimeLine />
                  </Box>
                </Container>
              </>
            )}
          />
        </PageWrapper>
      </StyledPageWrapper>
      <PricingSection
        pt="0"
        title="Suena bien, ¿verdad?"
        subtitle="Selecciona la opción que encaje mejor contigo."
      />
    </>
  )
}
export default HowItWorksPage
