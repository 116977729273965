import React from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Text } from "../../components/Core"
import Howitem1Icon from "../../images/svg/howitem1.svg"
import Howitem2Icon from "../../images/svg/howitem2.svg"
import Howitem3Icon from "../../images/svg/howitem3.svg"
import RightArrow from "../../images/svg/right-arrow-blue.svg"

const WidgetCol = styled(Col)`
  padding: 0px 16px !important;
  position: relative;

  @media (min-width: 768px) {
    &:nth-child(-n + 2):before {
      content: url(${RightArrow});
      text-align: end;
      right: 0;
      top: 7rem;
      border: 0;
      position: absolute;
      width: 100%;
    }
  }
`

export const ItemsDescription = () => {
  const sectionAttibutes = [
    {
      icon: Howitem1Icon,
      title: (
        <>
          Selecciona el plan que se <br />
          ajusta a tu necesidad
        </>
      ),
      subtitle:
        "Nuestros planes están diseñados para ti, pero si requieres asesoría adicional puedes contactarte con nuestro equipo de ventas.  ",
    },
    {
      icon: Howitem2Icon,
      title: (
        <>
          Completa el proceso <br /> de registro
        </>
      ),
      subtitle:
        "Después de seleccionar tu plan, completa tus datos y comienza a usar herramientas que te ayudarán a mejorar el día a día de tu profesión. ",
    },
    {
      icon: Howitem3Icon,
      title: (
        <>
          Libérate y gana beneficios <br /> con nosotros
        </>
      ),
      subtitle:
        "Accede a servicios financieros. Por cierto, no nos importa si debes mucho dinero o estás reportado, confiamos en ti.",
    },
  ]
  const getWidgetItem = (item, index) => {
    const primaryColor = "#002432"

    return (
      <WidgetCol xs="12" sm="6" md="4" className="mb-1 mt-4 ">
        <Box
          pt="2"
          pb="2"
          css={`
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 14rem;
          `}
        >
          <img src={item.icon} alt="left-form-image" />
        </Box>
        <Box
          pt="2"
          pb="2"
          css={`
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 3rem;
          `}
        >
          <Text
            fontSize="18px"
            mb={2}
            css={`
              line-height: 24px;
              font-weight: 600;
              letter-spacing: 0.02em;
              color: ${primaryColor};
            `}
          >
            {item.title}
          </Text>
          <Text
            fontSize={2}
            css={`
              line-height: 20px;
              font-weight: 400;
              letter-spacing: 0.02em;
              color: ${primaryColor};
            `}
          >
            {item.subtitle}
          </Text>
        </Box>
      </WidgetCol>
    )
  }

  return (
    <Row
      className="align-items-left justify-content-center"
      css={{ marginTop: "10rem" }}
    >
      {sectionAttibutes.map((item, index) => {
        return getWidgetItem(item, index)
      })}
    </Row>
  )
}
